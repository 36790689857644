<template>
  <div class="app app-project-task-mine v">
    <van-tabs v-model="current" class="flex v" border>
      <van-tab v-for="(s, k) in status" :key="k" :name="k" :title="s.name">
        <list url="/api/proTask/my" :params="{status: s.status}" style="height: 100%;" :show-status="k === 'ALL'" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import List from "./index";

export default {
  components: { List },
  data() {
    return {
      status: {
        ALL: { name: "全部", status: null },
        WAITTING: { name: "待开始", status: "WAITTING" },
        RUNING: { name: "进行中", status: "RUNING" },
        FINISH: { name: "已完成", status: "FINISH" },
        CANCEL: { name: "已取消", status: "CANCEL" },
      },
      current: "ALL",
    };
  },
};
</script>

<style lang="scss">
.app-project-task-mine {
  .van-tabs {
    .van-tabs__wrap {
      flex: 0 0;
      flex-basis: initial;
    }

    .van-tabs__content {
      flex: 1;

      .van-tab__pane {
        height: 100%;
        position: relative;

        .van-pull-refresh {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>