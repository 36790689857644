<template>
  <van-pull-refresh v-model="refreshing" style="overflow: hidden auto;" @refresh="reload">
    <van-list v-model="loading" :finished="finished" @load="load">
      <task-item v-for="t in tasks" :key="t.id" :task="t" />
    </van-list>
  </van-pull-refresh>
</template>

<script>
import request from "@/utils/request";
import TaskItem from "./item";

export default {
  components: { TaskItem },
  props: {
    url: {
      type: String,
      default: "api/proTask",
    },
    params: Object,
  },
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      tasks: [],
      refreshing: false,
      loading: false,
      finished: false,
    };
  },
  methods: {
    load() {
      this.loading = true;
      request({
        url: this.url,
        method: "get",
        params: {
          current: this.page,
          size: this.size,
          sort: [{ column: "planBegTime", asc: false }],
          ...(this.params || {}),
        },
      })
        .then((res) => {
          this.tasks.push(...res.content);
          this.total = res.totalElements;
          if (this.tasks.length >= this.total) this.finished = true;
          this.page++;
        })
        .finally(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    reload() {
      this.page = 1;
      this.tasks = [];
      this.load();
    },
  },
};
</script>